import React, { useContext, useEffect, useRef } from "react";
import PhoneInputField from "../formik/PhoneInputField";
import { Checkbox } from "../forms";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FaArrowRight } from "react-icons/fa6";
import CartContext from "../../context/cart-context";

const phoneRegex = /^\+1[0-9]{3}[0-9]{3}[0-9]{4}$/;

const QuoteSummaryCheckout = ({ emitForm, emitSubmit }) => {
  const { checkoutInfo, setCheckoutInfo } = useContext(CartContext);
  const customerFormRef = useRef();

  useEffect(() => {
    if (customerFormRef.current) {
      emitForm(customerFormRef);
    }
  }, [customerFormRef]);

  const handleSubmit = () => {
    emitSubmit();
  };

  return (
    <>
      <Formik
        innerRef={customerFormRef}
        initialValues={{
          firstName: checkoutInfo.firstName || "",
          lastName: checkoutInfo.lastName || "",
          email: checkoutInfo.email || "",
          phone: checkoutInfo.phone
            ? `+1${checkoutInfo.phone.replace(/^[+?]?1/g, "")}`
            : "",
          okToText:
            typeof checkoutInfo.okToText !== "undefined"
              ? checkoutInfo.okToText
              : true,
          callInfo: checkoutInfo.callInfo || "",
        }}
        validate={(values) => {
          let errors = {};

          if (!values.firstName) {
            errors.firstName = "Required";
          }
          if (!values.lastName) {
            errors.lastName = "Required";
          }
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.phone) {
            errors.phone = "Required";
          } else if (!phoneRegex.test(values.phone)) {
            errors.phone = "Invalid phone number format";
          }

          return errors;
        }}
        onSubmit={(values) => {
          setCheckoutInfo({
            ...checkoutInfo,
            ...values,
            phone: values.phone.replace(/^[+?]?1/g, ""),
          });
        }}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="mb-6">
                <label htmlFor="firstName" className="block">
                  First Name
                  <abbr className="required" title="Required">
                    *
                  </abbr>
                </label>
                <Field
                  autoComplete="given-name"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                />
                <ErrorMessage
                  className="text-red-dark text-sm"
                  name="firstName"
                  component="div"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="lastName" className="block">
                  Last Name
                  <abbr className="required" title="Required">
                    *
                  </abbr>
                </label>
                <Field
                  autoComplete="family-name"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                />
                <ErrorMessage
                  className="text-red-dark text-sm"
                  name="lastName"
                  component="div"
                />
              </div>

              <div className="mb-6">
                <label htmlFor="email" className="block">
                  Email
                  <abbr className="required" title="Required">
                    *
                  </abbr>
                </label>
                <Field
                  autoComplete="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                />
                <ErrorMessage
                  className="text-red-dark text-sm"
                  name="email"
                  component="div"
                />
              </div>

              <div className="mb-6">
                <label htmlFor="phone" className="block">
                  Phone Number
                  <abbr className="required" title="Required">
                    *
                  </abbr>
                </label>
                <Field
                  autoComplete="tel-national"
                  name="phone"
                  component={PhoneInputField}
                  id="phone"
                  country="United States"
                  placeholder={`(${process.env.PHONE_AREA_CODE}) 111-1111`}
                  className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                  type="tel"
                />
                <ErrorMessage
                  className="text-red-dark text-sm"
                  name="phone"
                  component="div"
                />
              </div>
            </div>

            <div className="mb-6 flex flex-wrap items-center gap-3">
              <Field
                component={Checkbox}
                name="okToText"
                id="okToText"
                label="Okay to text?"
                className="block"
                checked={values.okToText}
              />
              <p className="text-grey-dark">
                We only use your number to communicate the progress of your
                order and not to send promotional texts
              </p>
            </div>
          </Form>
        )}
      </Formik>
      <button
        type="button"
        onClick={handleSubmit}
        className="flex items-center gap-2 text-white px-4 py-2 no-underline rounded bg-black"
      >
        Next
        <FaArrowRight />
      </button>
    </>
  );
};
export default QuoteSummaryCheckout;
