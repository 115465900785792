import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "./modal";

const FAQs = ({ faqs, heading }) => {
  return (
    <div className="mt-4 lg:mt-10">
      <h2 className="text-lg lg:text-4xl font-bold">{heading}</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
        {faqs.map((faq, index) => {
          const imageData = getImage(faq.faqImage.localFile);
          const answer =
            faq.faqAnswer.substring(0, 3) + "A: " + faq.faqAnswer.substring(3);

          return (
            <div
              key={`faq-${index}`}
              className="bg-grey-lighter rounded-lg p-4 lg:p-6"
            >
              <GatsbyImage
                image={imageData}
                alt={faq.faqImage.altText || "faq"}
                className="rounded w-full object-cover h-48 mb-3"
              />
              <h4 className="text-lg lg:text-xl font-bold">
                Q: {faq.faqQuestion}
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: answer,
                }}
                className="leading-loose text-grey-darker"
              />
              {faq.faqGallery && (
                <Modal
                  buttonText="View Gallery"
                  buttonClasses="text-grey-dark underline"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    {faq.faqGallery.map((image, index) => {
                      const galleryImageData = getImage(image.localFile);
                      return (
                        <div key={`faq-gallery-image-${index}`}>
                          <GatsbyImage
                            image={galleryImageData}
                            alt={image.altText || "Product"}
                            className="rounded-lg w-full object-cover h-64"
                          />
                          <div
                            className="text-center my-3"
                            dangerouslySetInnerHTML={{
                              __html: image.caption,
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Modal>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default FAQs;
