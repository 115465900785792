import "yet-another-react-lightbox/styles.css";

import { useLocation } from "@reach/router";
import classNames from "classnames";
import { format } from "date-fns";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import QueryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { BiCircle, BiMinus, BiPlus, BiSolidCheckCircle } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa6";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { toast } from "react-toastify";
import Lightbox from "yet-another-react-lightbox";
import tailwindConfig from "../../tailwind.config";
import FAQs from "../components/partials/faqs";
import HowItWorks from "../components/partials/how-it-works";
import Modal from "../components/partials/modal";
import QuoteSummary from "../components/partials/quote-summary";
import GoogleReviews from "../components/sections/google-reviews";
import useAllProduct from "../hooks/useAllProduct";
import { Price } from "../utilities/price";

const componentStyles = {
  darkBackground: {
    Wasatch: `bg-${process.env.THEME_COLOR_PRIMARY}-darkest text-white`,
    Mountainland: `bg-yellow text-black`,
  },
  activeBorder: {
    Wasatch: `border-${process.env.THEME_COLOR_PRIMARY}-dark border-2`,
  },
  productTitle: {
    Wasatch: `text-${process.env.THEME_COLOR_PRIMARY}-darker`,
    Mountainland: `text-black`,
  },
  button: {
    Wasatch: `bg-${process.env.THEME_COLOR_PRIMARY}-darker text-white`,
    Mountainland: `bg-${process.env.THEME_COLOR_PRIMARY}`,
  },
  tab: {
    Wasatch: `border-b-${process.env.THEME_COLOR_PRIMARY}-darkest`,
    Mountainland: `bg-${process.env.THEME_COLOR_PRIMARY}`,
  },
};

const borderColor =
  process.env.THEME_COLOR_PRIMARY === "green"
    ? tailwindConfig.theme.colors.green["darkest"]
    : tailwindConfig.theme.colors.yellow["dark"];

const QuoteBuilder = () => {
  const location = useLocation();
  const search = useMemo(
    () => QueryString.parse(location.search),

    [location.search]
  );
  const allProductData = useAllProduct();
  const allProducts = allProductData
    ? allProductData.map(({ node }) => {
        return node;
      })
    : [];

  const windowWellCover = allProducts.find((product) => {
    return product.slug === "window-well-covers";
  });

  const skus = [
    "window-well-cover-steel-black",
    "window-well-cover-polycarbonate",
    "window-well-cover-steel-black-poly-cover",
  ];

  const sortMap = {
    "window-well-cover-steel-black": 1,
    "window-well-cover-steel-black-poly-cover": 2,
    "window-well-cover-polycarbonate": 3,
  };

  const windowWellVariations = windowWellCover
    ? windowWellCover.product.variations
        .filter((variation) => {
          return skus.includes(variation.variationSku);
        })
        .sort((a, b) => {
          return sortMap[a.variationSku] - sortMap[b.variationSku];
        })
    : [];

  const linerProduct = allProducts.find((product) => {
    return product.slug === "window-well-liners";
  });
  const linerProductVariations = linerProduct
    ? linerProduct.product.variations
    : [];

  const accessoryProducts = allProducts
    .filter((product) => {
      return product.product.category.includes("accessories");
    })
    .filter((product) => {
      return !product.slug.includes("topper");
    });

  const [quote, setQuote] = useState({
    covers: [],
    liners: [],
    accessories: [],
  });

  const coversNum = useMemo(() => {
    if (!quote.covers.length) {
      return 1;
    }

    return Number(
      quote.covers.reduce(
        (accumulator, cover) => accumulator + cover.quantity,
        0
      )
    );
  }, [quote.covers]);

  const linersNum = useMemo(() => {
    if (!quote.covers.length) {
      return 1;
    }

    return Number(
      quote.covers.reduce(
        (accumulator, cover) => accumulator + cover.quantity,
        0
      )
    );
  }, [quote.liners]);

  const flattenedProds = useMemo(() => {
    return [...quote.covers, ...quote.accessories, ...quote.liners];
  }, [quote]);

  useEffect(() => {
    if (!search.quoteId && sessionStorage.getItem("quote")) {
      setQuote(JSON.parse(sessionStorage.getItem("quote")));
    }
  }, []);

  useEffect(() => {
    const getQuoteData = async (quoteId) => {
      const res = await fetch(
        `${process.env.SWIFT_URL}/api/customer-lead/${quoteId}`
      );
      const data = await res.json();

      if (data) {
        setQuote({
          covers: data.cart.filter(
            (cartItem) => cartItem.category === "covers"
          ),
          liners: data.cart.filter(
            (cartItem) => cartItem.category === "liners"
          ),
          accessories: data.cart.filter(
            (cartItem) => cartItem.category === "accessories"
          ),
        });
        sessionStorage.setItem("quote", JSON.stringify(quote));
        setActiveTab(tabs?.at(-1));
      }

      return data;
    };
    if (search.quoteId) {
      getQuoteData(search.quoteId);
    }
  }, [search.quoteId]);

  useEffect(() => {
    sessionStorage.setItem("quote", JSON.stringify(quote));
  }, [quote]);

  const handleAddProduct = ({ id, qty, category, override }) => {
    const existingProduct = quote[category].find((p) => p.id === id);

    // update qty
    if (existingProduct) {
      const newQuote = {
        ...quote,
        [category]: quote[category].map((p) => {
          if (p.id === id) {
            return {
              ...existingProduct,
              quantity: override ? qty : p.quantity + qty,
            };
          }

          return p;
        }),
      };
      return setQuote(newQuote);
    }

    // new product
    let newProduct = null;

    if (category === "covers") {
      newProduct = windowWellCover.product.variations.find(
        (variation) => variation.variationSku === id
      );
    }

    if (category === "liners") {
      newProduct = linerProduct.product.variations.find(
        (variation) => variation.variationSku === id
      );
    }

    if (category === "accessories") {
      newProduct = accessoryProducts.find((p) => p.product.sku === id);
    }

    let productData = {};
    if (category === "accessories") {
      productData = {
        id: newProduct.product.sku,
        taxable: true,
        title: newProduct.product.mainH1,
        category: [category],
        basePrice: newProduct.product.basePrice,
        salePrice: newProduct.product.salePrice,
        quantity: qty,
        url: `/window-well-accessories/`,
        image: newProduct.featuredImage.node.localFile.publicURL,
      };
    } else {
      productData = {
        id: newProduct.variationSku,
        taxable: true,
        title: newProduct.variationName,
        category: [category],
        basePrice: newProduct.variationBasePrice,
        salePrice: newProduct.variationSalePrice,
        quantity: qty,
        url: `/window-well-${category}/`,
        image: newProduct.variationFeaturedImage.localFile.publicURL,
        color: newProduct.variationColor,
      };
    }

    const newQuote = {
      ...quote,
      [category]: [...quote[category], productData],
    };
    return setQuote(newQuote);
  };

  const removeProduct = (id, category) => {
    const updatedProducts = quote[category].filter(
      (product) => product.id !== id
    );

    const newQuote = {
      ...quote,
      [category]: updatedProducts,
    };
    return setQuote(newQuote);
  };

  const handleClearItems = () => {
    setQuote({
      covers: [],
      liners: [],
      accessories: [],
    });
  };

  const tabs =
    search.source === "liners"
      ? [
          {
            label: "Window Well Liners",
            id: "liners",
          },
          {
            label: "Accessories",
            id: "accessories",
          },
          {
            label: "Window Well Covers",
            id: "covers",
          },
          {
            label: "Summary",
            id: "summary",
          },
        ]
      : [
          {
            label: "Window Well Covers",
            id: "covers",
          },
          {
            label: "Accessories",
            id: "accessories",
          },
          {
            label: "Window Well Liners",
            id: "liners",
          },
          {
            label: "Summary",
            id: "summary",
          },
        ];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const activeTabIndex = useMemo(() => {
    return tabs.findIndex((tab) => tab.id === activeTab.id);
  }, [activeTab]);

  const nextTab = () => {
    const index = tabs.findIndex((tab) => tab.id === activeTab.id);
    const next = tabs[index + 1] || undefined;
    if (next) setActiveTab(next);
    window.scrollTo(0, 0);
  };

  const prevTab = () => {
    const index = tabs.findIndex((tab) => tab.id === activeTab.id);
    const prev = tabs[index - 1] || undefined;
    if (prev) setActiveTab(prev);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div
        className={classNames(
          componentStyles.darkBackground[process.env.COMPANY_NAME_SHORTER]
        )}
      >
        <div className="container mx-auto lg:pt-12 px-6">
          <h1 className="text-2xl lg:text-3xl font-semibold py-6 lg:py-10">
            Quote Builder
          </h1>
          <div className="flex gap-8 justify-between lg:justify-start leading-tighter">
            {activeTabIndex > 0 && (
              <button
                onClick={() => prevTab()}
                className="flex items-center gap-2 p-3 pl-0 text-white lg:hidden"
              >
                <IoArrowBack className="shrink-0" /> <span>Previous Step</span>
              </button>
            )}
            {tabs.map((tab) => {
              return (
                <button
                  key={tab.id}
                  className={classNames(
                    activeTab.id === tab.id ? "flex" : "hidden",
                    "items-center gap-2 py-4 border-b-8",
                    "lg:flex"
                  )}
                  style={{
                    borderColor: activeTab.id === tab.id ? "#fff" : borderColor,
                  }}
                  onClick={() =>
                    setActiveTab(tabs.find((t) => t.id === tab.id))
                  }
                >
                  {!!quote[tab.id]?.length && (
                    <BiSolidCheckCircle className="shrink-0" size={20} />
                  )}
                  {!quote[tab.id]?.length && (
                    <BiCircle className="shrink-0" size={20} />
                  )}
                  <span>{tab.label}</span>
                </button>
              );
            })}
            {activeTabIndex < 3 && (
              <button
                onClick={() => nextTab()}
                className="flex items-center gap-2 p-3 pr-0 text-white lg:hidden"
              >
                <span>Next Step</span> <IoArrowForward className="shrink-0" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="container mx-auto my-8 lg:my-12 px-6">
        <div className="flex items-center flex-wrap gap-3 mb-4">
          <div className="rounded-lg border border-grey py-2 px-3 text-grey-darker">
            Items:
          </div>
          {!quote.covers.length &&
            !quote.liners.length &&
            !quote.accessories.length && (
              <div className="rounded-lg border border-grey py-2 px-3 text-grey-darker">
                None
              </div>
            )}
          {!!flattenedProds.length &&
            flattenedProds.map((product) => {
              return (
                <div
                  key={product.id}
                  className="rounded-lg border border-grey py-1 px-3 flex items-center gap-2"
                >
                  <span>
                    {product.quantity}x {product.title}
                  </span>
                  <button
                    className="text-lg"
                    onClick={() =>
                      removeProduct(product.id, product.category[0])
                    }
                  >
                    &times;
                  </button>
                </div>
              );
            })}
        </div>
        {activeTab.id === "covers" && (
          <div>
            <div>
              {windowWellVariations &&
                windowWellVariations.map((variation, index) => {
                  const brownVersion = variation.variationSku.includes("black")
                    ? windowWellCover.product.variations.find(
                        (v) =>
                          v.variationSku ===
                          variation.variationSku.replace("black", "brown")
                      )
                    : false;
                  return (
                    <ProductControl
                      product={variation}
                      brownVersion={brownVersion}
                      emitProduct={handleAddProduct}
                      category="covers"
                      initialQty={search.source === "liners" ? linersNum : 1}
                      key={`cover-${index}`}
                    />
                  );
                })}
            </div>
            <div
              className={`bg-grey-lighter rounded-lg p-4 lg:py-6 lg:px-10 flex justify-end ${
                quote.covers.length ? "sticky bottom-0" : ""
              }`}
            >
              <div className="flex flex-col items-end">
                {activeTabIndex < 2 && (
                  <h4 className="lg:text-xl font-semibold mb-4">
                    Next, browse or select {tabs[activeTabIndex + 1].label}
                  </h4>
                )}
                {activeTabIndex === 2 && (
                  <h4 className="lg:text-xl font-semibold mb-4">
                    Lastly, view your quote summary
                  </h4>
                )}
                <div className="flex items-center gap-2">
                  {activeTabIndex > 0 && (
                    <button
                      className="py-2 px-4 rounded bg-grey-dark text-white flex items-center gap-2"
                      onClick={prevTab}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    className="py-2 px-4 rounded bg-black text-white flex items-center gap-2"
                    onClick={nextTab}
                  >
                    {quote.covers.length
                      ? "Next"
                      : "Continue without adding covers"}
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            </div>
            {windowWellCover?.product.faqs && (
              <FAQs
                faqs={windowWellCover.product.faqs}
                heading="Window Well Cover FAQs"
              />
            )}
          </div>
        )}
        {activeTab.id === "accessories" && (
          <div>
            {accessoryProducts &&
              accessoryProducts.map((product, index) => {
                return (
                  <ProductControl
                    product={product}
                    emitProduct={handleAddProduct}
                    category="accessories"
                    initialQty={
                      search.source === "liners" ? linersNum : coversNum
                    }
                    key={`accessory-${index}`}
                  />
                );
              })}
            <div
              className={`bg-grey-lighter rounded-lg p-4 lg:py-6 lg:px-10 flex justify-end ${
                quote.accessories.length ? "sticky bottom-0" : ""
              }`}
            >
              <div className="flex flex-col items-end">
                {activeTabIndex < 2 && (
                  <h4 className="lg:text-xl font-semibold mb-4">
                    Next, browse or select {tabs[activeTabIndex + 1].label}
                  </h4>
                )}
                {activeTabIndex === 2 && (
                  <h4 className="lg:text-xl font-semibold mb-4">
                    Lastly, view your quote summary
                  </h4>
                )}
                <div className="flex items-center gap-2">
                  {activeTabIndex > 0 && (
                    <button
                      className="py-2 px-4 rounded bg-grey-dark text-white flex items-center gap-2"
                      onClick={prevTab}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    className="py-2 px-4 rounded bg-black text-white flex items-center gap-2"
                    onClick={nextTab}
                  >
                    {quote.accessories.length
                      ? "Next"
                      : "Continue without adding accessories"}
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            </div>
            {accessoryProducts?.faqs && (
              <FAQs
                faqs={accessoryProducts.product.faqs}
                heading="Window Well Accessory FAQs"
              />
            )}
          </div>
        )}

        {activeTab.id === "liners" && (
          <div>
            {linerProductVariations &&
              linerProductVariations.map((variation, index) => {
                return (
                  <ProductControl
                    product={variation}
                    emitProduct={handleAddProduct}
                    category="liners"
                    initialQty={search.source === "liners" ? 1 : coversNum}
                    key={`liner-${index}`}
                  />
                );
              })}
            <div
              className={`bg-grey-lighter rounded-lg p-4 lg:py-6 lg:px-10 flex justify-end ${
                quote.liners.length ? "sticky bottom-0" : ""
              }`}
            >
              <div className="flex flex-col items-end">
                {activeTabIndex < 2 && (
                  <h4 className="lg:text-xl font-semibold mb-4">
                    Next, browse or select {tabs[activeTabIndex + 1].label}
                  </h4>
                )}
                {activeTabIndex === 2 && (
                  <h4 className="lg:text-xl font-semibold mb-4">
                    Lastly, view your quote summary
                  </h4>
                )}
                <div className="flex items-center gap-2">
                  {activeTabIndex > 0 && (
                    <button
                      className="py-2 px-4 rounded bg-grey-dark text-white flex items-center gap-2"
                      onClick={prevTab}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    className="py-2 px-4 rounded bg-black text-white flex items-center gap-2"
                    onClick={nextTab}
                  >
                    {quote.liners.length
                      ? "Next"
                      : "Continue without adding liners"}
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            </div>
            {linerProduct?.product.faqs && (
              <FAQs
                faqs={linerProduct.product.faqs}
                heading="Window Well Accessory FAQs"
              />
            )}
          </div>
        )}
        {activeTab.id === "summary" && (
          <>
            <div
              className={`bg-grey-lighter rounded-lg p-4 lg:py-6 lg:px-10 flex justify-start mb-6`}
            >
              <button
                className="py-2 px-4 rounded bg-grey-dark text-white flex items-center gap-2"
                onClick={prevTab}
              >
                Previous
              </button>
            </div>
            <QuoteSummary
              items={flattenedProds}
              emitAddProduct={handleAddProduct}
              emitRemoveProduct={removeProduct}
              emitClearItems={handleClearItems}
            />
            <GoogleReviews bgImage={false} />
          </>
        )}
        <HowItWorks />
      </div>
    </>
  );
};

const ProductImage = ({ image, classStyles }) => {
  const imageData = getImage(image.localFile);

  return (
    <GatsbyImage
      image={imageData}
      alt={image.altText || "Product"}
      className={`rounded-lg w-full object-cover ${classStyles}`}
    />
  );
};

const ProductControl = ({
  product,
  brownVersion = false,
  emitProduct,
  initialQty = 1,
  category,
}) => {
  const [selProduct, setSelProduct] = useState(product);
  const [qty, setQty] = useState(1);

  const addProduct = (quantity) => {
    const id =
      category === "accessories" ? product.product.sku : product.variationSku;
    emitProduct({ id, qty: quantity, category });
    toast.success(
      category === "accessories"
        ? selProduct.title + " added"
        : selProduct.variationName + " added"
    );
  };

  const saleEndsDate = lastDayOfMonth(new Date());
  const saleEnds = {
    date: format(saleEndsDate, "MMMM d"),
    days: differenceInCalendarDays(lastDayOfMonth(new Date()), new Date()),
  };

  return (
    <div className="rounded-lg border border-grey flex flex-wrap md:flex-nowrap mb-4 gap-6 p-4 lg:p-6">
      <div className="w-full md:w-1/4">
        <ProductImage
          image={
            selProduct.variationFeaturedImage || selProduct.featuredImage.node
          }
          classStyles={"h-48"}
        />
        <ProductGallery
          images={
            selProduct.variationImageGallery || selProduct.product?.imageGallery
          }
          thumbnails={
            selProduct.variationImageGalleryThumbnails ||
            selProduct.product?.imageGalleryThumbnails
          }
        />
      </div>
      <div className="w-full flex-1 flex flex-col justify-between">
        <div>
          <h4 className="text-lg font-bold mb-1">{selProduct.variationName}</h4>
          <div className="text-grey-darker flex items-center gap-4 mb-2">
            <Price
              value={selProduct.variationBasePrice}
              className={classNames([
                selProduct.variationSalePrice && "text-grey line-through",
                !selProduct.variationSalePrice &&
                  `text-${process.env.THEME_COLOR_PRIMARY}-darker text-lg font-medium`,
              ])}
            />
            {selProduct.variationSalePrice && (
              <>
                <Price
                  value={selProduct.variationSalePrice}
                  className={`text-${process.env.THEME_COLOR_PRIMARY}-darker text-lg font-medium`}
                />
                <span className="text-sm">
                  Sale Ends {saleEnds.date} &bull; {saleEnds.days} Days Left!
                </span>
              </>
            )}
          </div>
          <div className="text-xs text-grey-dark mb-3">
            One price any size up to 44" x 84"
          </div>
          <ProductDescription
            short={
              selProduct.variationShortDescription || selProduct.seo.metaDesc
            }
            long={selProduct.variationDescription || selProduct.content}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          {brownVersion && (
            <div className="flex gap-3 items-center mb-3 lg:mb-0">
              <button
                className={`p-2 border rounded-lg flex items-center gap-2 hover:bg-grey-lighter ${
                  selProduct.variationSku.includes("black")
                    ? componentStyles.activeBorder[
                        process.env.COMPANY_NAME_SHORTER
                      ]
                    : "border-grey"
                }`}
                onClick={() => setSelProduct(product)}
              >
                <i className="h-5 w-5 rounded-full bg-black" />
                <div>Black</div>
              </button>
              <button
                className={`p-2 border rounded-lg flex items-center gap-2 hover:bg-grey-lighter ${
                  selProduct.variationSku.includes("brown")
                    ? componentStyles.activeBorder[
                        process.env.COMPANY_NAME_SHORTER
                      ]
                    : "border-grey"
                }`}
                onClick={() => setSelProduct(brownVersion)}
              >
                <i className="h-5 w-5 rounded-full bg-brown" />
                <div>Brown + $20.00</div>
              </button>
            </div>
          )}
          <div className="flex items-center flex-wrap gap-3">
            <div className="flex items-center gap-3 rounded-lg border border-grey py-2 px-3">
              <button
                disabled={qty <= 0}
                onClick={() => setQty(qty - 1)}
                className="rounded-full bg-grey-light w-6 h-6 flex justify-center items-center"
              >
                <BiMinus />
              </button>
              <input
                type="number"
                className="text-grey-darker w-6 block text-center remove-number-spinner"
                value={qty}
                onChange={(e) => {
                  setQty(e.target.value);
                }}
              />
              <button
                onClick={() => setQty(qty + 1)}
                className="rounded-full bg-grey-light w-6 h-6 flex justify-center items-center"
              >
                <BiPlus />
              </button>
            </div>
            <button
              className={`rounded-lg ${
                componentStyles.button[process.env.COMPANY_NAME_SHORTER]
              } py-2 px-6`}
              onClick={() => addProduct(qty)}
            >
              Add
            </button>
            {qty < initialQty && (
              <>
                <span>or</span>
                <button
                  className={`rounded-lg bg-grey-light py-2 px-6`}
                  onClick={() => addProduct(initialQty)}
                >
                  Add {initialQty} To All{" "}
                  {category === "covers" ? "Liners" : "Wells"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductDescription = ({ long, short }) => {
  const regex = /(<([^>]+)>)/gi;
  const result = short.replace(regex, "");
  return (
    <div className="leading-loose mb-2">
      <span>{result}</span>
      <Modal
        buttonText="More Info"
        buttonClasses="text-grey-dark underline ml-2"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: long,
          }}
          className="leading-loose mb-2"
        />
      </Modal>
    </div>
  );
};

const ProductGallery = ({ images, thumbnails }) => {
  const [open, setOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(false);

  const galleryImages = images.map((image) => ({
    altText: image.altText,
    src: image.localFile.publicURL,
  }));

  return (
    <div>
      {galleryImages.length > 1 && (
        <div className="grid grid-cols-5 gap-2 mt-2">
          {galleryImages.map((img, index) => {
            // don't duplicate the first image
            if (index === 0) return null;

            if (index === 5)
              return (
                <button
                  className="bg-grey-lighter text-sm rounded text-grey-dark flex items-center justify-center"
                  key={index}
                  type="button"
                  onClick={() => {
                    setSlideIndex(index);
                    setOpen(true);
                  }}
                >
                  +5
                </button>
              );

            if (index > 5) return;

            const thumbnail = thumbnails[index];
            const thumbnailImageData = getImage(thumbnail.localFile);

            return (
              <button
                key={index}
                type="button"
                onClick={() => {
                  setSlideIndex(index);
                  setOpen(true);
                }}
              >
                <GatsbyImage
                  className="object-cover h-12 w-full rounded"
                  image={thumbnailImageData}
                  alt={img.altText || "Product"}
                  height="50"
                  width="50"
                />
              </button>
            );
          })}
        </div>
      )}
      <Lightbox
        close={() => setOpen(false)}
        controller={{
          closeOnPullDown: true,
          closeOnBackdropClick: true,
        }}
        index={slideIndex}
        open={open}
        slides={galleryImages}
      />
    </div>
  );
};

export default QuoteBuilder;
