import classNames from "classnames";
import React, { useState } from "react";
import { BiX } from "react-icons/bi";
import Modal from "react-modal";

const DefaultModal = ({ children, buttonText, buttonClasses, buttonIcon }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(true);
        }}
        className={classNames(buttonClasses)}
      >
        {buttonIcon}
        {buttonText}
      </button>

      <Modal
        isOpen={isOpen}
        contentLabel="Modal"
        className="bg-white shadow-lg rounded-lg p-6 mx-auto max-w-lg relative"
        style={{
          overlay: {
            zIndex: "100",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            display: "flex",
            alignItems: "center",
            padding: "20px",
          },
        }}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setIsOpen(false);
        }}
      >
        <button
          aria-label="Close modal"
          className={classNames(
            "absolute p-2 rounded-full bg-grey-light right-2 top-2 z-50",
            "lg:top-[-50px] lg:right-2"
          )}
          onClick={(event) => {
            event.stopPropagation();
            setIsOpen(false);
          }}
        >
          <BiX size={25} />
        </button>
        <div className="overflow-y-scroll max-h-[85vh] h-full lg:h-auto">
          {children}
        </div>
      </Modal>
    </>
  );
};
export default DefaultModal;
